import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  UPDATE_USER,
  UPDATE_ERROR,
  USERS_LOADED,
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  SET_CURRENT,
  CLEAR_CURRENT,
  CLEAR_ERRORS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };

    case USERS_LOADED:
      return {
        ...state,

        loading: false,

        users: action.payload.users,

        totalUsers: action.payload.totalUsers,
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],

        loading: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case EDIT_USER:
      return {
        ...state,
        users: state.users.map((users) =>
          users._id === action.payload.id ? action.payload : users
        ),
        loading: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((users) => users.id !== action.payload),
        loading: false,
      };
    case UPDATE_ERROR:
      return {
        ...state,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,

        isAuthenticated: true,
        loading: false,
      };

    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: action.payload,
      };

    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
