import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";

const DataReport = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, user } = authContext;
  const { dataReport, data } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dataReport");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchInput, setSearchInput] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    dataReport({
      startDate,
      endDate,
    });
    navigate("/dataReport");
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredData =
    data &&
    data.filter(
      (vehicle) =>
        vehicle.vehicleNumber &&
        vehicle.vehicleNumber.toUpperCase().includes(searchInput.toUpperCase())
    );

  return (
    <div>
      <Menu />
      <Header />

      {user && user.role === "admin" ? (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="content-fluid">
              <div className="row mt-2">
                <div className="col-md-9 ml-md-2">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Start Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setStartDate(new Date())}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2">
                        <label htmlFor="">End Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setEndDate(new Date())}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2 ">
                        <label htmlFor="">&nbsp;</label>
                        <button
                          className="btn btn-info btn-block "
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Data Report</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Search by Vehicle Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter vehicle number"
                          value={searchInput}
                          onChange={handleSearch}
                        />
                      </div>
                      <div
                        id="example2_wrapper"
                        className="dataTables_wrapper dt-bootstrap4"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6" />
                          <div className="col-sm-12 col-md-6" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              <table
                                id="example2"
                                className="table table-bordered table-hover dataTable dtr-inline collapsed"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th>Vehicle #</th>
                                    <th>Zone</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredData &&
                                    filteredData.map((vehicle) => (
                                      <Fragment>
                                        <tr className="odd">
                                          <td>{vehicle.vehicleNumber}</td>
                                          <td>{vehicle.entrySlotName}</td>
                                          <td>
                                            {new Date(
                                              vehicle.entryCreatedAt
                                            ).toLocaleDateString("en-GB")}
                                          </td>
                                        </tr>
                                      </Fragment>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default DataReport;
