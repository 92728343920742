import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from "chart.js";

//import data from "../../data.json";
import axios from "axios";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement
);

function Dashboard() {
  const [chartData, setChartData] = useState({});
  const [chartData2, setChartData2] = useState({});
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, loadUsers, totalUsers, setCurrent, user } =
    authContext;
  const {
    loadParkings,
    loadSlots,
    loadSales,

    getParkedVehicles,
    getParkedVehiclesToday,
    totalParked,

    totalParkedToday,
    parkedVehiclesToday,

    getCheckedOutVehiclesToday,
    totalCheckedOutToday,
    checkedOutVehiclesToday,

    mpesaSalesToday,
    cashSalesToday,
    totalSalesToday,
  } = rateContext;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    } else {
      navigate("/login");
    }

    getCheckedOutVehiclesToday();
    loadParkings(currentPage);
    loadSlots();
    loaduser();
    loadUsers();
    loadSales();
    getParkedVehicles();
    getParkedVehiclesToday();

    const fetchData = async () => {
      const { data } = await axios.get(
        "https://api.barwaqo.co.ke/api/sales/salesData"
      );
      setChartData({
        labels: data.map((item) => item.month_year),
        datasets: [
          {
            label: "Revenue",
            data: data.map((item) => item.totalSales),
            fill: true,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    };

    fetchData();

    const fetchdata2 = async () => {
      try {
        const response = await axios.get(
          "https://api.barwaqo.co.ke/api/parkings/parkedVehiclesYear"
        );
        const data2 = response.data;
        response.data;

        if (data2 && Array.isArray(data2)) {
          setChartData2({
            labels: data2.map((item) => item.month),
            datasets: [
              {
                label: "Monthly Vehicle Check-ins",
                data: data2.map((item) => item.totalCheckins),
                backgroundColor: "rgba(75,192,192,0.6)",
                borderColor: "rgba(75,192,192,1)",
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.error("Invalid or missing data in the response:", response);
        }
      } catch (error) {
        console.error("Error fetching data2:", error);
      }
    };
    fetchdata2();

    // eslint-disable-next-line
  }, [isAuthenticated, currentPage]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {user && user.role === "admin" ? (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{totalParkedToday && totalParkedToday}</h3>
                      <p>Total Parking</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>
                        {totalCheckedOutToday &&
                          totalCheckedOutToday.toLocaleString()}
                      </h3>
                      <p>Total CheckOuts Today</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{totalParked && totalParked.toLocaleString()}</h3>
                      <p>Vehicles in Yard</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>
                        {totalSalesToday && totalSalesToday.toLocaleString()}
                      </h3>

                      <p>Total Sales</p>
                    </div>

                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                    {/* <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>
                        {mpesaSalesToday && mpesaSalesToday.toLocaleString()}
                      </h3>

                      <p>M-PESA Sales</p>
                    </div>

                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                    {/* <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>
                        {cashSalesToday && cashSalesToday.toLocaleString()}
                      </h3>

                      <p>Cash Sales</p>
                    </div>

                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                    {/* <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">Yearly Revenue</h3>
                        <Link to="/checkOutReport">View Report</Link>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={{ height: "100%", width: "100%" }}
                    >
                      {chartData && chartData?.datasets && (
                        <Line
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "top",
                              },
                              title: {
                                display: true,
                                text: "Revenue",
                              },
                            },
                          }}
                          data={chartData}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="d-flex justify-content-between">
                        <h3 className="card-title">Vehicle Check In's</h3>
                        <Link to="/checkInReport">View Report</Link>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={{ height: "100%", width: "100%" }}
                    >
                      {chartData2 && chartData2?.datasets && (
                        <Bar
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "top",
                              },
                              title: {
                                display: true,
                                text: "Check-Ins",
                              },
                            },
                            scales: {
                              y: {
                                beginAtZero: true,
                                ticks: {
                                  stepSize: 1,
                                  precision: 0,
                                },
                              },
                            },
                          }}
                          data={chartData2}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <h2 className="card-title">Check-Ins Today</h2>
                      <div className="card-tools">
                        {/* <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-download" />
                        </a> */}
                        <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-bars" />
                        </a>
                      </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                      <table
                        id="example2"
                        class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr>
                            <th>Parking Code</th>
                            <th>Vehicle #</th>
                            <th>Contact</th>
                            <th>Zone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {parkedVehiclesToday &&
                            parkedVehiclesToday.map((vehicle) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td> {vehicle.id} </td>
                                    <td>{vehicle.vehicleNumber}</td>
                                    <td>{vehicle.phone}</td>
                                    <td>{vehicle.slotName}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <h2 className="card-title">Check-Outs Today</h2>
                      <div className="card-tools">
                        {/* <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-download" />
                        </a> */}
                        <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-bars" />
                        </a>
                      </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                      <table
                        id="example2"
                        class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr>
                            <th>Parking Code</th>
                            <th>Vehicle #</th>
                            <th>Zone</th>
                            <th>Paid</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkedOutVehiclesToday &&
                            checkedOutVehiclesToday.map((vehicle) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td> {vehicle.id} </td>
                                    <td>{vehicle.vehicleNumber}</td>
                                    <td>{vehicle.slotName}</td>
                                    <td>{vehicle.amountPaid}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{totalParkedToday.toLocaleString()}</h3>
                      <p>Total Parkings Today</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag" />
                    </div>
                    <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>{totalCheckedOutToday}</h3>
                      <p>Total CheckOuts</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                    <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{totalParked.toLocaleString()}</h3>
                      <p>Vehicles in Yard</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div>
                    <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <h2 className="card-title">Check-Ins Today</h2>
                      <div className="card-tools">
                        {/* <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-download" />
                        </a> */}
                        <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-bars" />
                        </a>
                      </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                      <table
                        id="example2"
                        class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr>
                            <th>Parking Code</th>
                            <th>Vehicle #</th>
                            <th>Contact</th>
                            <th>Zone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {parkedVehiclesToday &&
                            parkedVehiclesToday.map((vehicle) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td> {vehicle.id} </td>
                                    <td>{vehicle.vehicleNumber}</td>
                                    <td>{vehicle.phone}</td>
                                    <td>{vehicle.slotName}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header border-0">
                      <h2 className="card-title">Check-Outs Today</h2>
                      <div className="card-tools">
                        {/* <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-download" />
                        </a> */}
                        <a href="#" className="btn btn-tool btn-sm">
                          <i className="fas fa-bars" />
                        </a>
                      </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                      <table
                        id="example2"
                        class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr>
                            <th>Parking Code</th>
                            <th>Vehicle #</th>
                            <th>Zone</th>
                            <th>Paid</th>
                          </tr>
                        </thead>
                        <tbody>
                          {checkedOutVehiclesToday &&
                            checkedOutVehiclesToday.map((vehicle) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td> {vehicle.id} </td>
                                    <td>{vehicle.vehicleNumber}</td>
                                    <td>{vehicle.slotName}</td>
                                    <td>{vehicle.amountPaid}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
