import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const AddParking = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, current } = authContext;
  const {
    registerParking,
    loadSlots,
    loadRates,
    rates,
    slots,
    error,
    clearErrors,
  } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/addParking");
    }

    loaduser();
    loadSlots();
    loadRates();
    clearErrors();

    if (error !== null && error !== "none") {
      toast.error(error);
    } else if (error === "none") {
      toast.success("Successfuly added Parking");

      //setTimeout(props.history.push('/Logbooks'), 4000);
      setTimeout(navigate("/manageParking"), 4000);
      clearErrors();
      //props.history.push('/insurances');
    }

    // eslint-disable-next-line
  }, [isAuthenticated, error]);

  const [register, setRegister] = useState({
    vehicleOwner: "",
    vehicleNumber: "",
    rateName: "",
    slotName: "",
    phone: "",
  });
  let { rateName, vehicleNumber, vehicleOwner, slotName, phone } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  const onSubmit = async (e) => {
    e.preventDefault();

    await registerParking({
      rateName,
      vehicleNumber,
      vehicleOwner,
      slotName,
      phone,
    });

    // Check if registration was successful
  };

  return (
    <div>
      <Menu />
      <Header />

      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row justify-content-center ">
              <div className="col-md-6 mt-4">
                <div className="card card-info">
                  <div>
                    {/* Your component content */}

                    {/* Place ToastContainer at an appropriate location in the component tree */}
                    <ToastContainer
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      style={{ textAlign: "center" }}
                    />
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">PARKING INFORMATION</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Vehicle Owner
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Full Name"
                          name="vehicleOwner"
                          value={vehicleOwner}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Vehicle Plate #
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Number Plate"
                          name="vehicleNumber"
                          value={vehicleNumber}
                          onChange={onChange}
                          required
                        />
                      </div>
                      {slots && (
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Parking Zone
                          </label>
                          <select
                            className="form-control "
                            name="slotName"
                            value={slotName}
                            onChange={onChange}
                            required
                          >
                            <option></option>
                            {slots.map((slot) => (
                              <option key={slot.id} value={slot.slotName}>
                                {slot.slotName}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      {rates && (
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Parking Rate
                          </label>
                          <select
                            className="form-control "
                            name="rateName"
                            value={rateName}
                            onChange={onChange}
                            required
                          >
                            <option></option>
                            {rates.map((rate) => (
                              <option key={rate.id} value={rate.rateName}>
                                {rate.rateName}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone</label>
                        <input
                          type="number"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Phone Number"
                          name="phone"
                          value={phone}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Save Changes
                      </button>{" "}
                      &nbsp;
                      {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default AddParking;
