import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/auth/authContext";

//toast.configure();
const LogIn = () => {
  const authContext = useContext(AuthContext);
  const { login, error, isAuthenticated, loaduser } = authContext;
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  useEffect(() => {
    if (isAuthenticated) {
      loaduser();

      navigate("/");
    }
    if (error === "invalid credentials") {
      toast.error("invalid credentials");
    }
    //toast("invalid credentials");

    // eslint-disable-next-line
  }, [isAuthenticated, error]);

  const [admin, setAdmin] = useState({
    email: "",
    password: "",
  });
  const { email, password } = admin;
  const onChange = (e) =>
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    //check for validation
    login({
      email,
      password,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Adjust to control the vertical height of the centering container
      }}
    >
      <div className="login-box">
        <div>
          {/* Your component content */}

          {/* Place ToastContainer at an appropriate location in the component tree */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ textAlign: "center" }}
          />
        </div>
        <div className="login-logo">
          <b>Company</b>LTD
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={onSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={onChange}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8 mx-auto text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            {/* <p className="mx-auto text-center">
              <a href="#">I forgot my password</a>
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
