import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
const UpdateParking = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, current, user } = authContext;
  const { updateParking } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/editParking");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [update, setUpdate] = useState({
    vehicleOwner: current && current.vehicleOwner,
    vehicleNumber: current && current.vehicleNumber,
    rateName: current && current.rateName,
    slotName: current && current.slotName,
    phone: current && current.phone,
    balance: current && current.balance,
    ratePrice: current && current.ratePrice,
    status: current && current.status,
  });
  const {
    vehicleOwner,
    vehicleNumber,
    rateName,
    slotName,
    phone,
    balance,
    ratePrice,
    status,
  } = update;

  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    updateParking(
      {
        vehicleOwner,
        vehicleNumber,
        rateName,
        slotName,
        phone,
        balance,
        ratePrice,
        status,
      },
      current
    );
    navigate("/manageParking");
  };
  return (
    <div>
      <Menu />
      <Header />
      {user && user.role === "admin" ? (
        <div className="content-wrapper ">
          <section className="content ">
            <div className="container-fluid">
              <div className="row justify-content-center ">
                <div className="col-md-6 mt-4">
                  <div className="card card-info">
                    <div className="card-header">
                      <h3 className="card-title">UPDATE RATE</h3>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Owner
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Full Name"
                            name="vehicleOwner"
                            value={vehicleOwner}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Vehicle Plate #
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Number Plate"
                            name="vehicleNumber"
                            value={vehicleNumber}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Parking Slot
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Parking Slot"
                            name="slotName"
                            value={slotName}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Parking Rate
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Rate Name"
                            name="rateName"
                            value={rateName}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Rate Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Rate Price"
                            name="ratePrice"
                            value={ratePrice}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Balance</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Balance"
                            name="balance"
                            value={balance}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Status</label>
                          <select
                            className="form-control "
                            name="status"
                            value={status}
                            onChange={onChange}
                            required
                          >
                            <option></option>
                            <option>Paid</option>
                            <option>Pending</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Phone</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Phone Number"
                            name="phone"
                            value={phone}
                            onChange={onChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-info">
                          Save Changes
                        </button>{" "}
                        &nbsp;
                        {/* <button type="submit" className="btn btn-warning">
                         Back
                       </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default UpdateParking;
