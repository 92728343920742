import React, { useState, useEffect, useContext, Fragment } from "react";

import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const AddRate = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, user } = authContext;
  const { registerRate } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/addRate");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [register, setRegister] = useState({
    rateName: "",
    ratePrice: "",
  });
  let { rateName, ratePrice } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  const onSubmit = (e) => {
    e.preventDefault();

    registerRate({
      rateName,
      ratePrice,
    });

    navigate("/manageRate");
  };

  return (
    <div>
      <Menu />
      <Header />
      {user && user.role === "admin" ? (
        <div className="content-wrapper ">
          <section className="content ">
            <div className="container-fluid">
              <div className="row justify-content-center ">
                <div className="col-md-6 mt-4">
                  <div className="card card-info">
                    <div className="card-header">
                      <h3 className="card-title">PARKING RATE INFORMATION</h3>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Rate Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Number Plate"
                            name="rateName"
                            value={rateName}
                            onChange={onChange}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Rate Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Rate Price"
                            name="ratePrice"
                            value={ratePrice}
                            onChange={onChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-info">
                          Save Changes
                        </button>{" "}
                        &nbsp;
                        {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default AddRate;
