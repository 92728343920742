import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

function Menu() {
  //const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const { logout, loaduser, user, isAuthenticated } = authContext;
  const [menuItems, setMenuItems] = useState({
    users: false,
    rates: false,
    parking: false,
    parkingSlot: false,
    report: false,
    entry: false,
  });

  useEffect(() => {
    // if (isAuthenticated) {
    //   navigate("/checkInReport");
    // }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const toggleMenuItem = (menuItem) => {
    setMenuItems((prevMenuItems) => ({
      ...prevMenuItems,
      [menuItem]: !prevMenuItems[menuItem],
    }));
  };

  const onLogout = () => {
    logout();
  };
  // useEffect(() => {
  //   const trees = window.$('[data-widget="treeview"]');
  //   trees.Treeview("init");
  // }, []);

  if (user && user.role === "admin") {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <div className="brand-link">
            <img
              src="dist/img/AdminLTELogo.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: ".8" }}
            />
            <span className="brand-text font-weight-light">BARWAQO LTD</span>
          </div>
          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img
                  src="dist/img/user2-160x160.jpg"
                  className="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div className="info">
                <Link to="/settings">{user && user.name}</Link>
              </div>
            </div>
            {/* <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div> */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
              >
                <li className="nav-item ">
                  <Link to="/" className="nav-link ">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      Dashboard
                      <i className="right fas " />
                    </p>
                  </Link>
                </li>

                <li
                  className={`nav-item ${menuItems.users ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("users")}
                  >
                    <i class="nav-icon fas fa-users "></i>
                    <p>
                      Users
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addUser" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Users</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageUsers" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Users</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item ${menuItems.rates ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("rates")}
                  >
                    <i className="nav-icon fas fa-money-bill"></i>
                    <p>
                      Rates
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addRate" className="nav-link ">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Rates</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageRate" className="nav-link ">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Rates</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item ${menuItems.parking ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("parking")}
                  >
                    <i className="nav-icon fas fa-parking"></i>
                    <p>
                      Track GP
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addParking" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Parking</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageParking" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Parking</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="/checkout" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Check-Out Vehicle</p>
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li
                  className={`nav-item ${
                    menuItems.parkingSlot ? "menu-open" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("parkingSlot")}
                  >
                    <i className="nav-icon fas fa-tree"></i>
                    <p>
                      Parking Zone
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addSlot" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Zone</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageSlot" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Zone</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item ${menuItems.entry ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("entry")}
                  >
                    <i class="nav-icon fas fa-book "></i>
                    <p>
                      Stock Taking
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addEntry" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Data Entry</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageEntry" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Entries</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item ${menuItems.report ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("report")}
                  >
                    <i className="nav-icon fas fa-list" />
                    <p>
                      Report
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/checkInReport" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Check-In Report</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/checkOutReport" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Check-Out Report</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/dataReport" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Data Report</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item ">
                  <Link to="/settings" className="nav-link">
                    <i className="nav-icon fas fa-cog" />
                    <p>
                      Settings
                      <i className="right fas " />
                    </p>
                  </Link>
                </li>

                <li className="nav-item ">
                  <a href="/login" className="nav-link " onClick={onLogout}>
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p>
                      Logout
                      <i className="right fas " />
                    </p>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    );
  } else {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <div className="brand-link">
            <img
              src="dist/img/AdminLTELogo.png"
              alt="AdminLTE Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: ".8" }}
            />
            <span className="brand-text font-weight-light">BARWAQO LTD</span>
          </div>
          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img
                  src="dist/img/user2-160x160.jpg"
                  className="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div className="info">
                <Link to="/settings">{user && user.name}</Link>
              </div>
            </div>
            {/* <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw" />
              </button>
            </div>
          </div>
        </div> */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
              >
                <li className="nav-item ">
                  <Link to="/" className="nav-link ">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      Dashboard
                      <i className="right fas " />
                    </p>
                  </Link>
                </li>
                <li
                  className={`nav-item ${menuItems.parking ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("parking")}
                  >
                    <i className="nav-icon fas fa-parking"></i>
                    <p>
                      Track GP
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addParking" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Parking</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageParking" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Parking</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="/checkout" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Check-Out Vehicle</p>
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li
                  className={`nav-item ${menuItems.rates ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("rates")}
                  >
                    <i className="nav-icon fas fa-money-bill"></i>
                    <p>
                      Rates
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* <li className="nav-item">
                      <Link to="/addRate" className="nav-link ">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Rates</p>
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to="/manageRate" className="nav-link ">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Rates</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item ${
                    menuItems.parkingSlot ? "menu-open" : ""
                  }`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("parkingSlot")}
                  >
                    <i className="nav-icon fas fa-tree"></i>
                    <p>
                      Parking Zone
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addSlot" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Add Zone</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageSlot" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Zone</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`nav-item ${menuItems.entry ? "menu-open" : ""}`}
                >
                  <a
                    href="#"
                    className="nav-link"
                    onClick={() => toggleMenuItem("entry")}
                  >
                    <i class="nav-icon fas fa-book "></i>
                    <p>
                      Stock Taking
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/addEntry" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Data Entry</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/manageEntry" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Manage Entries</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item ">
                  <Link to="/settings" className="nav-link">
                    <i className="nav-icon fas fa-cog" />
                    <p>
                      Settings
                      <i className="right fas " />
                    </p>
                  </Link>
                </li>

                <li className="nav-item ">
                  <a href="/login" className="nav-link " onClick={onLogout}>
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p>
                      Logout
                      <i className="right fas " />
                    </p>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    );
  }
}

export default Menu;
