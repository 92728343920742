import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
const CheckoutVehicle = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, current } = authContext;
  const { checkOutVehicle } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/checkout");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const calculatedAmount =
    Math.max(
      1,
      Math.abs(new Date(current.createdAt) - new Date()) / (1000 * 60 * 60 * 24)
    ) * current.ratePrice;

  const [register, setRegister] = useState({
    totalAmount: calculatedAmount,
    amountPaid: "",
    paymentMode: "",
  });
  let { totalAmount, amountPaid, paymentMode } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  // Create a new Date object
  const currentDate = new Date();

  let daysDifference = Math.round(
    Math.max(
      1,
      Math.abs(new Date(current.createdAt) - new Date(currentDate)) /
        (1000 * 60 * 60 * 24)
    )
  );

  totalAmount = daysDifference * current.ratePrice;

  const onSubmit = (e) => {
    e.preventDefault();

    checkOutVehicle(
      {
        totalAmount,
        amountPaid,
        paymentMode,
      },
      current.id
    );

    navigate("/manageParking");
  };

  return (
    <div>
      <Menu />
      <Header />

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CHECK OUT VEHICLE</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Check-out</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">CHEK-OUT VEHICLE </h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div
                        id="example2_wrapper"
                        className="dataTables_wrapper dt-bootstrap4"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6" />
                          <div className="col-sm-12 col-md-6" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              <table
                                id="example2"
                                className="table table-bordered table-hover dataTable dtr-inline collapsed"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Rendering engine: activate to sort column ascending"
                                    >
                                      Parking Code
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Vehicle
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Check-In Date
                                    </th>{" "}
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Days
                                    </th>{" "}
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Platform(s): activate to sort column ascending"
                                    >
                                      Total Amount
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Slot
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Amount Paid
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Payment Mode
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="odd">
                                    <td className="dtr-control">
                                      {current.id}
                                    </td>
                                    <td className="sorting_1">
                                      {current.vehicleNumber}
                                    </td>
                                    <td className="sorting_1">
                                      {new Date(
                                        current.createdAt
                                      ).toLocaleDateString("en-GB")}
                                    </td>

                                    <td className="dtr-control">
                                      {
                                        (daysDifference = Math.round(
                                          Math.max(
                                            1,
                                            Math.abs(
                                              new Date(current.createdAt) -
                                                new Date(currentDate)
                                            ) /
                                              (1000 * 60 * 60 * 24)
                                          )
                                        ))
                                      }
                                    </td>

                                    <td className="dtr-control">
                                      {totalAmount}
                                    </td>

                                    <td className="dtr-control">
                                      {current.slotName}
                                    </td>
                                    <td className="dtr-control">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter Amount"
                                        name="amountPaid"
                                        value={amountPaid}
                                        onChange={onChange}
                                        required
                                      />
                                    </td>
                                    <td className="dtr-control">
                                      <div className="form-group">
                                        <select
                                          className="form-control "
                                          name="paymentMode"
                                          value={paymentMode}
                                          onChange={onChange}
                                        >
                                          <option></option>
                                          <option>M-PESA</option>
                                          <option>CASH</option>
                                          <option>BANK</option>
                                        </select>
                                      </div>
                                    </td>

                                    <td className="sorting_1">
                                      <div className="d-flex flex-wrap">
                                        <button
                                          class="btn btn-info btn-sm flex-grow-1 mb-2"
                                          type="submit"
                                        >
                                          Check-Out
                                        </button>{" "}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default CheckoutVehicle;
