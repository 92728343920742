export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const USER_LOADED = "USER_LOADED";

export const UPDATE_ERROR = "UPDATE_ERROR";

export const UPDATE_USER = "UPDATE_USER";

export const USERS_LOADED = "USERS_LOADED";

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const EDIT_USER = "EDIT_USER";

export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";

export const RATES_LOADED = "RATES_LOADED";

export const ADD_RATE_SUCCESS = "ADD_RATE_SUCCESS";

export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS";

export const EDIT_RATE = "EDIT_RATE";

export const SLOTS_LOADED = "SLOTS_LOADED";

export const ADD_SLOTS_SUCCESS = "ADD_SLOTS_SUCCESS";

export const DELETE_SLOTS_SUCCESS = "DELETE_SLOTS_SUCCESS";

export const EDIT_SLOTS = "EDIT_SLOTS";

export const PARKINGS_LOADED = "PARKINGS_LOADED";
export const EDIT_PARKINGS = "EDIT_PARKINGS";
export const ADD_PARKINGS_SUCCESS = "ADD_PARKINGS_SUCCESS";
export const DELETE_PARKINGS_SUCCESS = "DELETE_PARKINGS_SUCCESS";

export const ADD_CHECKOUT_SUCCESS = "ADD_CHECKOUT_SUCCESS";
export const SALES_REPORT = "SALES_REPORT";
export const CHECKIN_REPORT = "CHECKIN_REPORT";
export const SALES_LOADED = "SALES_LOADED";

export const PARKED_LOADED = "PARKED_LOADED";
export const PARKED_TODAY_LOADED = "PARKED_TODAY_LOADED";
export const CHECKEDOUT_TODAY_LOADED = "CHECKEDOUT_TODAY_LOADED";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ERRORS = "SET_ERRORS";
export const PARKING_SEARCH = "PARKING_SEARCH";
export const ADD_ENTRY = "ADD_ENTRY";
export const ENTRY_LOADED = "ENTRY_LOADED";
export const EDIT_ENTRY = "EDIT_ENTRY";
export const DELETE_ENTRY = "DELETE_ENTRY";
export const DATA_REPORT = "DATA_REPORT";
