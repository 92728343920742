import React from "react";

function Header() {
  return (
    <header className="main-header">
      <nav className="navbar navbar-expand navbar-white navbar-light">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item d-none d-md-inline-block">
              <div className="nav-link">PARKING MANAGEMENT SYSTEM</div>
            </li>
          </ul>
        </div>
        {/* "Bars" icon visible on small and medium screens */}
        <ul className="navbar-nav d-block d-sm-none ml-auto">
          <li className="nav-item ">
            <div className="nav-link">PARKING MANAGEMENT SYSTEM</div>
          </li>
        </ul>
        <ul className="navbar-nav d-block d-md-none">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        {/* "Bars" icon visible on large screens */}
        <ul className="navbar-nav d-none d-md-block ml-auto">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        {/* Rest of the navbar items */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          {/* Add more items as needed */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
