import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "../auth/authContext";
import authReducer from "../auth/authReducer";
import setAuthToken from "../../utils/setAuthToken";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  UPDATE_USER,
  UPDATE_ERROR,
  USERS_LOADED,
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER,
  SET_CURRENT,
  CLEAR_CURRENT,
  CLEAR_ERRORS,
} from "../types";
const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    loading: true,
    user: null,
    users: [],
    error: null,
    current: null,
    totalUsers: 0,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  //load user
  const loaduser = async () => {
    //@todo setAuthToken
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        "https://api.barwaqo.co.ke/api/users/me/profile"
      );
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

  //Login user
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/users/login",
        formData,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // loaduser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 3000);
    }
  };

  //Update user profile
  const updateProfile = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        "https://api.barwaqo.co.ke/api/users/me/profile",
        formData,
        config
      );
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //Update user

  const updateUser = async (formData, current) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `https://api.barwaqo.co.ke/api/users/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: EDIT_USER,
        payload: res.data,
      });
      loadUsers();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //Delete User
  const deleteUser = async (id) => {
    try {
      await axios.delete(`https://api.barwaqo.co.ke/api/users/${id}`);

      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //load users
  const loadUsers = async () => {
    try {
      const res = await axios.get("https://api.barwaqo.co.ke/api/users");
      dispatch({
        type: USERS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const registerUser = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/users/register",
        formData,
        config
      );
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: res.data,
      });
      loadUsers();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  // Set Current Contact
  const setCurrent = (user) => {
    dispatch({ type: SET_CURRENT, payload: user });
  };

  // Clear Current Contact
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  //Logout
  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        users: state.users,
        error: state.error,
        current: state.current,
        totalUsers: state.totalUsers,

        login,
        loaduser,
        logout,
        updateProfile,
        loadUsers,
        registerUser,
        deleteUser,
        updateUser,
        setCurrent,
        clearCurrent,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthState;
