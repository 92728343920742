import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const AddUser = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);

  const { registerUser } = authContext;

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     props.history.push('/userRegistration/create');
  //   }

  //   loadUsers();

  //   // eslint-disable-next-line
  // }, [error, isAuthenticated, props.history]);

  const [register, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: "",
    confirmPassword: "",
    shift: "",
  });
  let { name, email, password, phone, role, confirmPassword, shift } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  const onSubmit = (e) => {
    e.preventDefault();

    registerUser({
      name,
      email,
      password,
      phone,
      role,
      confirmPassword,
      shift,
    });

    navigate("/manageUsers");
  };

  return (
    <div>
      <Menu />
      <Header />
      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row justify-content-center ">
              <div className="col-md-6 mt-4">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">USER INFORMATION</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Full Name"
                          name="name"
                          value={name}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          name="email"
                          value={email}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone</label>
                        <input
                          type="number"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Phoe Number"
                          name="phone"
                          value={phone}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Role</label>
                        <select
                          className="form-control "
                          name="role"
                          value={role}
                          onChange={onChange}
                          required
                        >
                          <option></option>
                          <option>admin</option>
                          <option>user</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Shift</label>
                        <select
                          className="form-control "
                          name="shift"
                          value={shift}
                          onChange={onChange}
                          required
                        >
                          <option></option>
                          <option>Day</option>
                          <option>Night</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Save Changes
                      </button>{" "}
                      &nbsp;
                      {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default AddUser;
