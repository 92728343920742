import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const ManageParking = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, setCurrent, user } = authContext;
  const {
    loadParkings,
    parkings,
    deleteParking,
    totalItems,
    searchParking,
    search,
  } = rateContext;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchV, setSearchV] = useState({
    searchInput: "",
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/manageParking");
    }

    loadParkings(currentPage);
    loaduser();

    // eslint-disable-next-line
  }, [isAuthenticated, currentPage]);

  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   searchParking(searchField);
  // };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create a new Date object
  const currentDate = new Date();

  // Get individual components of the date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
  const day = currentDate.getDate();

  // Format the date as a string (in DD/MM/YYYY format)
  const formattedDate =
    (day < 10 ? "0" : "") +
    day +
    "/" +
    (month < 10 ? "0" : "") +
    month +
    "/" +
    year;

  let daysDifference;

  let { searchInput } = searchV;

  const onChange = (e) => {
    setSearchV({
      ...searchV,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await searchParking(searchInput);

    // Check if registration was successful
  };

  // const handleSearch = (e) => {
  //   setSearchV(e.target.value);

  // };

  const filteredParking =
    parkings &&
    parkings.filter(
      (vehicle) =>
        vehicle.vehicleNumber &&
        vehicle.vehicleNumber
          .toUpperCase()
          .includes(searchInput && searchInput.toUpperCase())
    );

  return (
    <div>
      <Menu />
      <Header />

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PARKING INFORMATION</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Manage Parking</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">MANAGE PARKING </h3>
                  </div>
                  <div className="card-body">
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter vehicle number"
                            name="searchInput"
                            value={searchInput}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-md-3 mb-2">
                          <button
                            type="submit"
                            className="btn btn-info form-control"
                          >
                            Search
                          </button>{" "}
                        </div>
                      </div>
                    </form>

                    <div
                      id="example2_wrapper"
                      className="dataTables_wrapper dt-bootstrap4"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6" />
                        <div className="col-sm-12 col-md-6" />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div class="table-responsive">
                            <table
                              id="example2"
                              class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                              aria-describedby="example2_info"
                            >
                              <thead>
                                <tr>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Rendering engine: activate to sort column ascending"
                                  >
                                    Parking Code
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Vehicle
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Date In
                                  </th>{" "}
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Date Out
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Current
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Platform(s): activate to sort column ascending"
                                  >
                                    Days
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Platform(s): activate to sort column ascending"
                                  >
                                    Rate
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Platform(s): activate to sort column ascending"
                                  >
                                    Total Amount
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Platform(s): activate to sort column ascending"
                                  >
                                    Balance
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Engine version: activate to sort column ascending"
                                  >
                                    Zone
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Engine version: activate to sort column ascending"
                                  >
                                    Shift
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Platform(s): activate to sort column ascending"
                                  >
                                    Status
                                  </th>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Engine version: activate to sort column ascending"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredParking && searchInput === ""
                                  ? filteredParking.map((parking) => {
                                      return (
                                        <Fragment>
                                          <tr className="odd">
                                            <td className="dtr-control">
                                              {" "}
                                              {parking.id}{" "}
                                            </td>
                                            <td className="sorting_1">
                                              {parking.vehicleNumber}
                                            </td>
                                            <td className="dtr-control">
                                              {new Date(
                                                parking.createdAt
                                              ).toLocaleDateString("en-GB")}
                                            </td>
                                            <td className="dtr-control">
                                              {parking.dateOut
                                                ? new Date(
                                                    parking.dateOut
                                                  ).toLocaleDateString("en-GB")
                                                : "-----"}
                                            </td>
                                            <td className="dtr-control">
                                              {formattedDate}
                                            </td>

                                            <td className="dtr-control">
                                              {
                                                (daysDifference = Math.round(
                                                  Math.max(
                                                    1,
                                                    Math.abs(
                                                      new Date(
                                                        parking.createdAt
                                                      ) - new Date(currentDate)
                                                    ) /
                                                      (1000 * 60 * 60 * 24)
                                                  )
                                                ))
                                              }
                                            </td>

                                            <td className="dtr-control">
                                              {parking.ratePrice}
                                            </td>

                                            <td className="dtr-control">
                                              {daysDifference *
                                                parking.ratePrice}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.balance}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.slotName}
                                            </td>
                                            <td className="dtr-control">
                                              {parking.shift}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.status === "Paid" ? (
                                                <span class="badge badge-success">
                                                  {parking.status}
                                                </span>
                                              ) : (
                                                <span class="badge badge-warning">
                                                  {parking.status}
                                                </span>
                                              )}
                                              &nbsp;
                                              {parking.isCheckOut
                                                ? "Checked-Out"
                                                : "Yard"}
                                            </td>

                                            <td className="sorting_1">
                                              <div className="">
                                                {user &&
                                                user.role === "admin" ? (
                                                  <Link to="/editParking">
                                                    <button
                                                      class="btn btn-warning btn-sm flex-grow-1 mb-2"
                                                      href="#"
                                                      onClick={() =>
                                                        setCurrent(parking)
                                                      }
                                                    >
                                                      <i class="fas fa-pencil-alt"></i>{" "}
                                                      &nbsp; Edit
                                                    </button>{" "}
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                &nbsp;
                                                {user &&
                                                user.role === "admin" ? (
                                                  <button
                                                    class="btn btn-danger btn-sm flex-grow-1 mb-2"
                                                    href="#"
                                                    onClick={() => {
                                                      if (
                                                        window.confirm(
                                                          "Do you want to remove Parking?"
                                                        )
                                                      ) {
                                                        deleteParking(
                                                          parking.id
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <i class="fas fa-trash"></i>
                                                    &nbsp; Delete
                                                  </button>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                &nbsp;
                                                {!parking.isCheckOut && (
                                                  <Link to="/checkout">
                                                    <button
                                                      className="btn btn-info btn-sm flex-grow-1 mb-2"
                                                      onClick={() =>
                                                        setCurrent(parking)
                                                      }
                                                    >
                                                      Check-Out
                                                    </button>{" "}
                                                  </Link>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })
                                  : search &&
                                    search.map((parking) => {
                                      return (
                                        <Fragment>
                                          <tr className="odd">
                                            <td className="dtr-control">
                                              {" "}
                                              {parking.id}{" "}
                                            </td>
                                            <td className="sorting_1">
                                              {parking.vehicleNumber}
                                            </td>
                                            <td className="dtr-control">
                                              {new Date(
                                                parking.createdAt
                                              ).toLocaleDateString("en-GB")}
                                            </td>
                                            <td className="dtr-control">
                                              {parking.dateOut
                                                ? new Date(
                                                    parking.dateOut
                                                  ).toLocaleDateString("en-GB")
                                                : "-----"}
                                            </td>
                                            <td className="dtr-control">
                                              {formattedDate}
                                            </td>

                                            <td className="dtr-control">
                                              {
                                                (daysDifference = Math.round(
                                                  Math.max(
                                                    1,
                                                    Math.abs(
                                                      new Date(
                                                        parking.createdAt
                                                      ) - new Date(currentDate)
                                                    ) /
                                                      (1000 * 60 * 60 * 24)
                                                  )
                                                ))
                                              }
                                            </td>

                                            <td className="dtr-control">
                                              {parking.ratePrice}
                                            </td>

                                            <td className="dtr-control">
                                              {daysDifference *
                                                parking.ratePrice}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.balance}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.slotName}
                                            </td>
                                            <td className="dtr-control">
                                              {parking.shift}
                                            </td>

                                            <td className="dtr-control">
                                              {parking.status === "Paid" ? (
                                                <span class="badge badge-success">
                                                  {parking.status}
                                                </span>
                                              ) : (
                                                <span class="badge badge-warning">
                                                  {parking.status}
                                                </span>
                                              )}
                                              &nbsp;
                                              {parking.isCheckOut
                                                ? "Checked-Out"
                                                : "Yard"}
                                            </td>

                                            <td className="sorting_1">
                                              <div className="">
                                                {user &&
                                                user.role === "admin" ? (
                                                  <Link to="/editParking">
                                                    <button
                                                      class="btn btn-warning btn-sm flex-grow-1 mb-2"
                                                      href="#"
                                                      onClick={() =>
                                                        setCurrent(parking)
                                                      }
                                                    >
                                                      <i class="fas fa-pencil-alt"></i>{" "}
                                                      &nbsp; Edit
                                                    </button>{" "}
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                &nbsp;
                                                {user &&
                                                user.role === "admin" ? (
                                                  <button
                                                    class="btn btn-danger btn-sm flex-grow-1 mb-2"
                                                    href="#"
                                                    onClick={() => {
                                                      if (
                                                        window.confirm(
                                                          "Do you want to remove Parking?"
                                                        )
                                                      ) {
                                                        deleteParking(
                                                          parking.id
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <i class="fas fa-trash"></i>
                                                    &nbsp; Delete
                                                  </button>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                &nbsp;
                                                {!parking.isCheckOut && (
                                                  <Link to="/checkout">
                                                    <button
                                                      className="btn btn-info btn-sm flex-grow-1 mb-2"
                                                      onClick={() =>
                                                        setCurrent(parking)
                                                      }
                                                    >
                                                      Check-Out
                                                    </button>{" "}
                                                  </Link>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="example2_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 10 of {totalItems} entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                            id="example2_paginate"
                          >
                            <ul className="pagination">
                              <li
                                className={`paginate_button page-item previous ${
                                  currentPage === 1 ? "disabled" : ""
                                }`}
                                id="example2_previous"
                              >
                                <a
                                  href="#"
                                  aria-controls="example2"
                                  tabIndex={0}
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChange(currentPage - 1)
                                  }
                                >
                                  Previous
                                </a>
                              </li>

                              {Array.from(
                                { length: Math.ceil(totalItems / pageSize) },
                                (_, index) => (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      index + 1 === currentPage ? "active" : ""
                                    }`}
                                  >
                                    <a
                                      href="#"
                                      aria-controls="example2"
                                      tabIndex={0}
                                      className="page-link"
                                      onClick={() =>
                                        handlePageChange(index + 1)
                                      }
                                    >
                                      {index + 1}
                                    </a>
                                  </li>
                                )
                              )}

                              <li
                                className={`paginate_button page-item next ${
                                  currentPage ===
                                  Math.ceil(totalItems / pageSize)
                                    ? "disabled"
                                    : ""
                                }`}
                                id="example2_next"
                              >
                                <a
                                  href="#"
                                  aria-controls="example2"
                                  tabIndex={0}
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChange(currentPage + 1)
                                  }
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default ManageParking;
