import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const ManageSlot = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, setCurrent, user } = authContext;
  const { loadSlots, slots, deleteSlot } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/manageSlot");
    }

    loadSlots();
    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div>
      <Menu />
      <Header />

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PARKING ZONES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Parking Zone</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">MANAGE PARKING ZONES </h3>
                  </div>
                  <div className="card-body">
                    <div
                      id="example2_wrapper"
                      className="dataTables_wrapper dt-bootstrap4"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6" />
                        <div className="col-sm-12 col-md-6" />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="table-responsive">
                            {user && user.role === "admin" ? (
                              <table
                                id="example2"
                                class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Rendering engine: activate to sort column ascending"
                                    >
                                      Zone Code
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Zone Name
                                    </th>

                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {slots &&
                                    slots.map((slot) => {
                                      return (
                                        <Fragment>
                                          <tr className="odd">
                                            <td className="dtr-control">
                                              {" "}
                                              {slot.id}{" "}
                                            </td>
                                            <td className="sorting_1">
                                              {slot.slotName}
                                            </td>

                                            <td className="sorting_1">
                                              <div className="">
                                                <Link to="/editSlot">
                                                  <button
                                                    class="btn btn-info btn-sm flex-grow-1 mb-2"
                                                    href="#"
                                                    onClick={() =>
                                                      setCurrent(slot)
                                                    }
                                                  >
                                                    <i class="fas fa-pencil-alt"></i>{" "}
                                                    &nbsp; Edit
                                                  </button>{" "}
                                                </Link>
                                                &nbsp;
                                                <button
                                                  class="btn btn-danger btn-sm flex-grow-1 mb-2"
                                                  href="#"
                                                  onClick={() => {
                                                    if (
                                                      window.confirm(
                                                        "Do you want to remove Parking Slot?"
                                                      )
                                                    ) {
                                                      deleteSlot(slot.id);
                                                    }
                                                  }}
                                                >
                                                  <i class="fas fa-trash"></i>
                                                  &nbsp; Delete
                                                </button>
                                              </div>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            ) : (
                              <table
                                id="example2"
                                class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Rendering engine: activate to sort column ascending"
                                    >
                                      Zone Code
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Zone Name
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {slots &&
                                    slots.map((slot) => {
                                      return (
                                        <Fragment>
                                          <tr className="odd">
                                            <td className="dtr-control">
                                              {" "}
                                              {slot.id}{" "}
                                            </td>
                                            <td className="sorting_1">
                                              {slot.slotName}
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default ManageSlot;
