import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import AuthContext from "../../context/auth/authContext";
const UpdateProfile = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const { user, updateProfile, isAuthenticated, loaduser } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/settings");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [update, setUpdate] = useState({
    name: user && user.name,
    email: user && user.email,
    phone: user && user.phone,
    password: "",
    confirmPassword: "",
  });
  const { name, email, phone, password, confirmPassword } = update;

  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    updateProfile({
      name,
      email,
      phone,
      password,
      confirmPassword,
    });
    navigate("/login");
  };
  return (
    <div>
      <Menu />
      <Header />
      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row justify-content-center ">
              <div className="col-md-6 mt-4">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">UPDATE PROFILE</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Full Name"
                          name="name"
                          value={name}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          name="email"
                          value={email}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone</label>
                        <input
                          type="number"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Phoe Number"
                          name="phone"
                          value={phone}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Save Changes
                      </button>{" "}
                      &nbsp;
                      {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default UpdateProfile;
