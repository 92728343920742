//import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/layout/Header";
import Menu from "./components/layout/Menu";
import Dashboard from "./components/layout/Dashboard";
import Footer from "./components/layout/Footer";
import LogIn from "./components/auth/LogIn";
import ManageUsers from "./components/users/ManageUsers";
import AddUser from "./components/users/AddUser";
import ManageParking from "./components/parkings/manageParking";
import AddParking from "./components/parkings/AddParking";
import AddRate from "./components/rates/AddRate";
import ManageRate from "./components/rates/ManageRate";
import CheckoutVehicle from "./components/parkings/CheckoutVehicle";
import UpdateProfile from "./components/auth/UpdateProfile";
import AddSlot from "./components/slots/AddSlot";
import ManageSlot from "./components/slots/ManageSlot";
import CheckInReport from "./components/report/CheckInReport";
import CheckOutReport from "./components/report/CheckoutReport";
import UpdateUser from "./components/users/UpdateUser";
import AddEntry from "./components/slots/AddEntry";
import ManageEntry from "./components/slots/ManageEntry";
//states
import AuthState from "./context/auth/AuthState";
import RateState from "./context/rate/RateState";
import UpdateRate from "./components/rates/UpdateRate";
import UpdateSlot from "./components/slots/UpdateSlot";
import UpdateParking from "./components/parkings/UpdateParking";
import UpdateEntry from "./components/slots/UpdateEntry";
import DataReport from "./components/report/DataReport";

function App() {
  return (
    <AuthState>
      <RateState>
        <Router>
          <Routes>
            {/* Define your routes using the Route component within the Routes element */}
            <Route path="/login" element={<LogIn />} />

            {/* Your main layout with components goes here */}
            <Route
              path="/"
              element={
                <div className="wrapper">
                  <Header />
                  <Menu />
                  <Dashboard />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/manageUsers"
              element={
                <div className="wrapper">
                  <ManageUsers />
                </div>
              }
            />

            <Route
              path="/addUser"
              element={
                <div className="wrapper">
                  <AddUser />
                </div>
              }
            />

            <Route
              path="/editUser"
              element={
                <div className="wrapper">
                  <UpdateUser />
                </div>
              }
            />

            <Route
              path="/manageParking"
              element={
                <div className="wrapper">
                  <ManageParking />
                </div>
              }
            />
            <Route
              path="/addParking"
              element={
                <div className="wrapper">
                  <AddParking />
                </div>
              }
            />

            <Route
              path="/addRate"
              element={
                <div className="wrapper">
                  <AddRate />
                </div>
              }
            />

            <Route
              path="/manageRate"
              element={
                <div className="wrapper">
                  <ManageRate />
                </div>
              }
            />
            <Route
              path="/checkout"
              element={
                <div className="wrapper">
                  <CheckoutVehicle />
                </div>
              }
            />
            <Route
              path="/settings"
              element={
                <div className="wrapper">
                  <UpdateProfile />
                </div>
              }
            />
            <Route
              path="/addSlot"
              element={
                <div className="wrapper">
                  <AddSlot />
                </div>
              }
            />
            <Route
              path="/addEntry"
              element={
                <div className="wrapper">
                  <AddEntry />
                </div>
              }
            />
            <Route
              path="/manageEntry"
              element={
                <div className="wrapper">
                  <ManageEntry />
                </div>
              }
            />
            <Route
              path="/manageSlot"
              element={
                <div className="wrapper">
                  <ManageSlot />
                </div>
              }
            />
            <Route
              path="/checkInReport"
              element={
                <div className="wrapper">
                  <CheckInReport />
                </div>
              }
            />
            <Route
              path="/checkOutReport"
              element={
                <div className="wrapper">
                  <CheckOutReport />
                </div>
              }
            />
            <Route
              path="/dataReport"
              element={
                <div className="wrapper">
                  <DataReport />
                </div>
              }
            />

            <Route
              path="/editRate"
              element={
                <div className="wrapper">
                  <UpdateRate />
                </div>
              }
            />

            <Route
              path="/editParking"
              element={
                <div className="wrapper">
                  <UpdateParking />
                </div>
              }
            />

            <Route
              path="/editSlot"
              element={
                <div className="wrapper">
                  <UpdateSlot />
                </div>
              }
            />
            <Route
              path="/editEntry"
              element={
                <div className="wrapper">
                  <UpdateEntry />
                </div>
              }
            />
          </Routes>
        </Router>
      </RateState>
    </AuthState>
  );
}

export default App;
