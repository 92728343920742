import React, { useState, useEffect, useContext, Fragment } from "react";

import { useNavigate } from "react-router-dom";
//import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const AddSlot = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  // const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  //const { isAuthenticated, loaduser, setCurrent } = authContext;
  const { registerSlot } = rateContext;

  const [register, setRegister] = useState({
    slotName: "",
  });
  let { slotName } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  const onSubmit = (e) => {
    e.preventDefault();

    registerSlot({
      slotName,
    });

    navigate("/manageSlot");
  };

  return (
    <div>
      <Menu />
      <Header />
      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row justify-content-center ">
              <div className="col-md-6 mt-4">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">PARKING ZONE INFORMATION</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Zone Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Parking Slot"
                          name="slotName"
                          value={slotName}
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Save Changes
                      </button>{" "}
                      &nbsp;
                      {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default AddSlot;
