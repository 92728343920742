import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
const UpdateRate = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, current, user } = authContext;
  const { updateRate } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/editRate");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [update, setUpdate] = useState({
    rateName: current && current.rateName,
    ratePrice: current && current.ratePrice,
  });
  const { rateName, ratePrice } = update;

  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    updateRate({ rateName, ratePrice }, current);
    navigate("/manageRate");
  };
  return (
    <div>
      <Menu />
      <Header />
      {user && user.role === "admin" ? (
        <div className="content-wrapper ">
          <section className="content ">
            <div className="container-fluid">
              <div className="row justify-content-center ">
                <div className="col-md-6 mt-4">
                  <div className="card card-info">
                    <div className="card-header">
                      <h3 className="card-title">UPDATE RATE</h3>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Rate Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Number Plate"
                            name="rateName"
                            value={rateName}
                            onChange={onChange}
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Rate Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Enter Rate Price"
                            name="ratePrice"
                            value={ratePrice}
                            onChange={onChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-info">
                          Save Changes
                        </button>{" "}
                        &nbsp;
                        {/* <button type="submit" className="btn btn-warning">
                         Back
                       </button> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default UpdateRate;
