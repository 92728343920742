import React, { useState, useEffect, useContext, Fragment } from "react";

import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const AddEntry = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated } = authContext;
  const { registerEntry } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/addEntry");
    }

    //loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [formData, setFormData] = useState({
    slotName: "",
    vehicleNumber: "",
  });
  const [disableInputs, setDisableInputs] = useState(false);

  const { slotName, vehicleNumber } = formData;

  const [entries, setEntries] = useState([]);

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    // Create a new entry object
    const newEntry = {
      slotName,
      vehicleNumber,
    };

    // Update the entries array with the new entry
    setEntries([...entries, newEntry]);

    if (entries.length >= 10) {
      setDisableInputs(true);
    }

    // Clear the form after submission
    setFormData({
      slotName: "",
      vehicleNumber: "",
    });
  };

  const onDelete = (index) => {
    // Create a copy of the entries array
    const updatedEntries = [...entries];

    // Remove the entry at the specified index
    updatedEntries.splice(index, 1);

    // Update the state with the modified entries array
    setEntries(updatedEntries);
  };

  //   const [register, setRegister] = useState({
  //     slotName: "",
  //     vehicleNumber: "",
  //   });
  //   let { slotName, vehicleNumber } = register;
  //   const onChange = (e) =>
  //     setRegister({
  //       ...register,
  //       [e.target.name]: e.target.value,
  //       //[e.target.SeatNumber]: e.target.value.split(","),
  //     });

  const onSubmit2 = (e) => {
    e.preventDefault();

    ("hi");

    registerEntry({
      entries,
    });

    navigate("/manageEntry");
  };
  return (
    <div>
      <Menu />
      <Header />
      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-12 mt-4">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">PARKING DATA</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="slotName" className="form-label">
                          Zone Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="slotName"
                          name="slotName"
                          value={slotName}
                          onChange={onChange}
                          disabled={disableInputs}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="vehicleNumber" className="form-label">
                          Vehicle Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="vehicleNumber"
                          name="vehicleNumber"
                          value={vehicleNumber}
                          onChange={onChange}
                          disabled={disableInputs}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Add Data
                      </button>{" "}
                      &nbsp;
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {entries.length > 0 && (
              <div className="row  mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">MANAGE PARKING DATA</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>Entries</th>
                              <th>Zone Name</th>
                              <th>Vehicle #</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {entries.map((entry, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{entry.slotName}</td>
                                <td>{entry.vehicleNumber}</td>
                                <td>
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => onDelete(index)}
                                    >
                                      <i className="fas fa-trash"></i> Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {entries.length >= 10 && (
                      <form onSubmit={onSubmit2}>
                        <div className="card-footer">
                          <button type="submit" className="btn btn-info">
                            Save Changes
                          </button>{" "}
                          &nbsp;
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AddEntry;
