import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
const ManageEntry = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, setCurrent, user } = authContext;
  const { loadEntries, entry, deleteEntry, totalEntries } = rateContext;
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/manageEntry");
    }

    loadEntries();
    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredEntry =
    entry &&
    entry.filter(
      (vehicle) =>
        vehicle.vehicleNumber &&
        vehicle.vehicleNumber.toUpperCase().includes(searchInput.toUpperCase())
    );

  return (
    <div>
      <Menu />
      <Header />

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PARKING DATA ENTRY </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Parking Information
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      MANAGE PARKING DATA : <b>({totalEntries} Entries) </b>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="col-md-3 mb-2">
                      <label htmlFor="">Search by Vehicle Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter vehicle number"
                        value={searchInput}
                        onChange={handleSearch}
                      />
                    </div>
                    <div
                      id="example2_wrapper"
                      className="dataTables_wrapper dt-bootstrap4"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6" />
                        <div className="col-sm-12 col-md-6" />
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="table-responsive">
                            <table
                              id="example2"
                              class="table table-bordered table-hover dataTable dtr-inline collapsed table-sm"
                              aria-describedby="example2_info"
                            >
                              <thead>
                                <tr>
                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Rendering engine: activate to sort column ascending"
                                  >
                                    P-Code
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Zone Name
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Vehicle #
                                  </th>
                                  <th
                                    className="sorting sorting_asc"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Browser: activate to sort column descending"
                                    aria-sort="ascending"
                                  >
                                    Staff
                                  </th>

                                  <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="example2"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Engine version: activate to sort column ascending"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredEntry &&
                                  filteredEntry.map((vehicle) => {
                                    return (
                                      <Fragment>
                                        <tr className="odd">
                                          <td className="dtr-control">
                                            {" "}
                                            {vehicle.id}{" "}
                                          </td>
                                          <td className="sorting_1">
                                            {vehicle.slotName}
                                          </td>
                                          <td className="sorting_1">
                                            {vehicle.vehicleNumber}
                                          </td>
                                          <td className="sorting_1">
                                            <span className="badge badge-warning">
                                              {vehicle.staffName}
                                            </span>
                                          </td>

                                          <td className="sorting_1">
                                            <div className="">
                                              <Link to="/editEntry">
                                                <button
                                                  class="btn btn-info btn-sm flex-grow-1 mb-2"
                                                  href="#"
                                                  onClick={() =>
                                                    setCurrent(vehicle)
                                                  }
                                                >
                                                  <i class="fas fa-pencil-alt"></i>{" "}
                                                  &nbsp; Edit
                                                </button>{" "}
                                              </Link>
                                              &nbsp;
                                              <button
                                                class="btn btn-danger btn-sm flex-grow-1 mb-2"
                                                href="#"
                                                onClick={() => {
                                                  if (
                                                    window.confirm(
                                                      "Do you want to remove Parking data?"
                                                    )
                                                  ) {
                                                    deleteEntry(vehicle.id);
                                                  }
                                                }}
                                              >
                                                <i class="fas fa-trash"></i>
                                                &nbsp; Delete
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </Fragment>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default ManageEntry;
