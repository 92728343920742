import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import AuthContext from "../../context/auth/authContext";
const UpdateUser = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const {
    user,
    updateProfile,
    isAuthenticated,
    loaduser,
    updateUser,
    current,
  } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/editUser");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [update, setUpdate] = useState({
    name: current && current.name,
    email: current && current.email,
    phone: current && current.phone,
    role: current && current.role,
    shift: current && current.shift,
  });
  const { name, email, phone, role, shift } = update;

  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    updateUser(
      {
        name,
        email,
        phone,
        role,
        shift,
      },
      current
    );
    navigate("/manageUsers");
  };
  return (
    <div>
      <Menu />
      <Header />
      <div className="content-wrapper ">
        <section className="content ">
          <div className="container-fluid">
            <div className="row justify-content-center ">
              <div className="col-md-6 mt-4">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 className="card-title">UPDATE USER</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Full Name"
                          name="name"
                          value={name}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          name="email"
                          value={email}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone</label>
                        <input
                          type="number"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Phoe Number"
                          name="phone"
                          value={phone}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Role</label>
                        <select
                          className="form-control "
                          name="role"
                          value={role}
                          onChange={onChange}
                          required
                        >
                          <option></option>
                          <option>admin</option>
                          <option>user</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Shift</label>
                        <select
                          className="form-control "
                          name="shift"
                          value={shift}
                          onChange={onChange}
                          required
                        >
                          <option></option>
                          <option>Day</option>
                          <option>Night</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-info">
                        Save Changes
                      </button>{" "}
                      &nbsp;
                      {/* <button type="submit" className="btn btn-warning">
                        Back
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default UpdateUser;
