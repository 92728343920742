import React, { useReducer } from "react";
import axios from "axios";
import RateContext from "../rate/rateContext";
import rateReducer from "../rate/rateReducer";

import {
  UPDATE_ERROR,
  RATES_LOADED,
  ADD_RATE_SUCCESS,
  DELETE_RATE_SUCCESS,
  EDIT_RATE,
  EDIT_SLOTS,
  DELETE_SLOTS_SUCCESS,
  SLOTS_LOADED,
  ADD_SLOTS_SUCCESS,
  PARKINGS_LOADED,
  EDIT_PARKINGS,
  ADD_PARKINGS_SUCCESS,
  DELETE_PARKINGS_SUCCESS,
  ADD_CHECKOUT_SUCCESS,
  SALES_REPORT,
  CHECKIN_REPORT,
  SALES_LOADED,
  PARKED_LOADED,
  PARKED_TODAY_LOADED,
  CHECKEDOUT_TODAY_LOADED,
  CLEAR_ERRORS,
  PARKING_SEARCH,
  ADD_ENTRY,
  ENTRY_LOADED,
  EDIT_ENTRY,
  DELETE_ENTRY,
  DATA_REPORT,
} from "../types";
const RateState = (props) => {
  const initialState = {
    rates: [],
    slots: [],
    error: null,
    parkings: [],
    totalItems: 0,
    totalSlots: 0,
    checkouts: [],
    checkins: [],
    startDate: null,
    endDate: null,
    totalSales: 0,
    sumOfAmountPaid: 0,
    sales: [],
    totalParked: 0,
    parkedVehicles: [],
    totalParkedToday: 0,
    parkedVehiclesToday: [],
    totalCheckedOutToday: 0,
    checkedOutVehiclesToday: [],
    mpesaSales: 0,
    cashSales: 0,
    search: [],
    entry: [],
    totalEntries: 0,
    data: [],
    mpesaSalesToday: 0,
    cashSalesToday: 0,
    totalSalesToday: 0,
  };
  const [state, dispatch] = useReducer(rateReducer, initialState);

  //Update Rate

  const updateRate = async (formData, current) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `https://api.barwaqo.co.ke/api/rates/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: EDIT_RATE,
        payload: res.data,
      });
      loadRates();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //Delete Rate
  const deleteRate = async (id) => {
    try {
      await axios.delete(`https://api.barwaqo.co.ke/api/rates/${id}`);

      dispatch({
        type: DELETE_RATE_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //load Rates
  const loadRates = async () => {
    try {
      const res = await axios.get("https://api.barwaqo.co.ke/api/rates");
      dispatch({
        type: RATES_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register Rates
  const registerRate = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/rates/addRate",
        formData,
        config
      );
      dispatch({
        type: ADD_RATE_SUCCESS,
        payload: res.data,
      });
      loadRates();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //Update slot

  const updateSlot = async (formData, current) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `https://api.barwaqo.co.ke/api/slots/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: EDIT_SLOTS,
        payload: res.data,
      });
      loadRates();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  const updateEntry = async (formData, current) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `https://api.barwaqo.co.ke/api/entry/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: EDIT_ENTRY,
        payload: res.data,
      });
      loadEntries();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };
  //Delete Entry
  const deleteEntry = async (id) => {
    try {
      await axios.delete(`https://api.barwaqo.co.ke/api/entry/${id}`);

      dispatch({
        type: DELETE_ENTRY,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //Delete Slot
  const deleteSlot = async (id) => {
    try {
      await axios.delete(`https://api.barwaqo.co.ke/api/slots/${id}`);

      dispatch({
        type: DELETE_SLOTS_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //load Slots
  const loadSlots = async () => {
    try {
      const res = await axios.get("https://api.barwaqo.co.ke/api/slots");
      dispatch({
        type: SLOTS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register Slot
  const registerSlot = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/slots/addSlot",
        formData,
        config
      );
      dispatch({
        type: ADD_SLOTS_SUCCESS,
        payload: res.data,
      });
      loadSlots();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register Entry
  const registerEntry = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/entry/addEntry",
        formData,
        config
      );
      dispatch({
        type: ADD_ENTRY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //load Slots
  const loadEntries = async () => {
    try {
      const res = await axios.get("https://api.barwaqo.co.ke/api/entry");
      dispatch({
        type: ENTRY_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //load parkings
  const loadParkings = async (currentPage) => {
    try {
      const res = await axios.get(
        `https://api.barwaqo.co.ke/api/parkings?pageNumber=${currentPage}`
      );
      dispatch({
        type: PARKINGS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //load parkings
  const getParkedVehicles = async () => {
    try {
      const res = await axios.get(
        `https://api.barwaqo.co.ke/api/parkings/parkedVehicles`
      );
      dispatch({
        type: PARKED_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //load parkings
  const getParkedVehiclesToday = async () => {
    try {
      const res = await axios.get(
        `https://api.barwaqo.co.ke/api/parkings/parkedVehiclesToday`
      );
      dispatch({
        type: PARKED_TODAY_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //load checked out today
  const getCheckedOutVehiclesToday = async () => {
    try {
      const res = await axios.get(
        `https://api.barwaqo.co.ke/api/sales/checkedOutToday`
      );
      dispatch({
        type: CHECKEDOUT_TODAY_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register parking
  const registerParking = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/parkings/addParking",
        formData,
        config
      );
      dispatch({
        type: ADD_PARKINGS_SUCCESS,
        payload: res.data,
      });
      loadParkings();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 3000);
    }
  };

  //Update parking

  const updateParking = async (formData, current) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.put(
        `https://api.barwaqo.co.ke/api/parkings/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: EDIT_PARKINGS,
        payload: res.data,
      });
      loadParkings();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //Delete Parking
  const deleteParking = async (id) => {
    try {
      await axios.delete(`https://api.barwaqo.co.ke/api/parkings/${id}`);

      dispatch({
        type: DELETE_PARKINGS_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //check out
  const checkOutVehicle = async (formData, id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `https://api.barwaqo.co.ke/api/sales/${id}`,
        formData,
        config
      );
      dispatch({
        type: ADD_CHECKOUT_SUCCESS,
        payload: res.data,
      });
      //loadSlots();
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //get all checkouts
  const loadSales = async () => {
    try {
      const res = await axios.get("https://api.barwaqo.co.ke/api/sales");
      dispatch({
        type: SALES_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  const salesReport = async (formData) => {
    const config = {
      headers: {
        Accept: "multipart/form-data",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/sales/salesRange",
        formData,
        config
      );
      dispatch({
        type: SALES_REPORT,
        payload: res.data.sales,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  const checkinReport = async (formData) => {
    const config = {
      headers: {
        Accept: "multipart/form-data",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/sales/checkInRange",
        formData,
        config
      );
      dispatch({
        type: CHECKIN_REPORT,
        payload: res.data.checkins,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  const dataReport = async (formData) => {
    const config = {
      headers: {
        Accept: "multipart/form-data",
      },
    };
    try {
      const res = await axios.post(
        "https://api.barwaqo.co.ke/api/entry/dataRange",
        formData,
        config
      );
      dispatch({
        type: DATA_REPORT,
        payload: res.data.parkings,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //search for parking
  const searchParking = async (searchField) => {
    try {
      const res = await axios.get(
        `https://api.barwaqo.co.ke/api/parkingS/search?searchField=${searchField}`
      );
      dispatch({
        type: PARKING_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ERROR,
      });
    }
  };

  //Clear Error
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <RateContext.Provider
      value={{
        rates: state.rates,
        slots: state.slots,
        error: state.error,
        parkings: state.parkings,
        totalItems: state.totalItems,
        checkouts: state.checkouts,
        checkins: state.checkins,
        totalSlots: state.totalSlots,
        totalSales: state.totalSales,
        sales: state.sales,
        sumOfAmountPaid: state.sumOfAmountPaid,
        totalParked: state.totalParked,
        parkedVehicles: state.parkedVehicles,
        totalParkedToday: state.totalParkedToday,
        parkedVehiclesToday: state.parkedVehiclesToday,
        totalCheckedOutToday: state.totalCheckedOutToday,
        checkedOutVehiclesToday: state.checkedOutVehiclesToday,
        mpesaSales: state.mpesaSales,
        cashSales: state.cashSales,
        search: state.search,
        entry: state.entry,
        totalEntries: state.totalEntries,
        data: state.data,
        mpesaSalesToday: state.mpesaSalesToday,
        cashSalesToday: state.cashSalesToday,
        totalSalesToday: state.totalSalesToday,

        loadRates,
        registerRate,
        updateRate,
        deleteRate,

        loadSlots,
        updateSlot,
        deleteSlot,
        registerSlot,

        loadParkings,
        updateParking,
        registerParking,
        deleteParking,
        checkOutVehicle,
        salesReport,
        checkinReport,
        loadSales,
        getParkedVehicles,
        getParkedVehiclesToday,
        getCheckedOutVehiclesToday,
        clearErrors,
        searchParking,
        registerEntry,
        loadEntries,
        updateEntry,
        deleteEntry,
        dataReport,
      }}
    >
      {props.children}
    </RateContext.Provider>
  );
};
export default RateState;
