import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";

const CheckInReport = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, user } = authContext;
  const { checkinReport, checkins } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/checkInReport");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchInput, setSearchInput] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    checkinReport({
      startDate,
      endDate,
    });
    navigate("/checkInReport");
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredCheckins = checkins.filter(
    (checkin) =>
      checkin.vehicleNumber &&
      checkin.vehicleNumber.toUpperCase().includes(searchInput.toUpperCase())
  );

  return (
    <div>
      <Menu />
      <Header />

      {user && user.role === "admin" ? (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="content-fluid">
              <div className="row mt-2">
                <div className="col-md-9 ml-md-2">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Start Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setStartDate(new Date())}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2">
                        <label htmlFor="">End Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setEndDate(new Date())}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2 ">
                        <label htmlFor="">&nbsp;</label>
                        <button
                          className="btn btn-info btn-block "
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Check In Report</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Search by Vehicle Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter vehicle number"
                          value={searchInput}
                          onChange={handleSearch}
                        />
                      </div>
                      <div
                        id="example2_wrapper"
                        className="dataTables_wrapper dt-bootstrap4"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6" />
                          <div className="col-sm-12 col-md-6" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              <table
                                id="example2"
                                className="table table-bordered table-hover dataTable dtr-inline collapsed"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th>Parking Code</th>
                                    <th>Vehicle #</th>
                                    <th>Vehicle Owner</th>
                                    <th>Date In</th>
                                    <th>Date Out</th>
                                    <th>Zone</th>
                                    <th>Contact</th>
                                    <th>Status</th>
                                    <th>Staff</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredCheckins.map((checkin) => (
                                    <Fragment>
                                      <tr className="odd" key={checkin.id}>
                                        <td>{checkin.id}</td>
                                        <td>
                                          {checkin.vehicleNumber.toUpperCase()}
                                        </td>
                                        <td>
                                          {checkin.vehicleOwner.toUpperCase()}
                                        </td>
                                        <td>
                                          {new Date(
                                            checkin.createdAt
                                          ).toLocaleDateString("en-GB")}
                                        </td>
                                        <td>
                                          {checkin.dateOut
                                            ? new Date(
                                                checkin.dateOut
                                              ).toLocaleDateString("en-GB")
                                            : "-----"}
                                        </td>
                                        <td>
                                          {checkin.slotName.toUpperCase()}
                                        </td>
                                        <td>{checkin.phone}</td>
                                        <td>
                                          {checkin.status === "Paid" ? (
                                            <span className="badge badge-success">
                                              {checkin.status.toUpperCase()}
                                            </span>
                                          ) : (
                                            <span className="badge badge-warning">
                                              {checkin.status.toUpperCase()}
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <span className="badge badge-warning">
                                            {checkin.staffName.toUpperCase()}
                                          </span>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default CheckInReport;
