import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../layout/Menu";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AuthContext from "../../context/auth/authContext";
import RateContext from "../../context/rate/rateContext";
const CheckOutReport = () => {
  const navigate = useNavigate(); // Use useNavigate hook to access the history object
  const authContext = useContext(AuthContext);
  const rateContext = useContext(RateContext);
  const { isAuthenticated, loaduser, user } = authContext;
  const { salesReport, checkouts } = rateContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/checkOutReport");
    }

    loaduser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchInput, setSearchInput] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    salesReport({
      startDate,
      endDate,
    });

    navigate("/checkOutReport");
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredCheckouts =
    checkouts &&
    checkouts.filter(
      (checkout) =>
        checkout.vehicleNumber &&
        checkout.vehicleNumber.toUpperCase().includes(searchInput.toUpperCase())
    );

  return (
    <div>
      <Menu />
      <Header />

      {user && user.role === "admin" ? (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>VEHICLES CHECK OUT REPORT</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Report on Vehicles Checked Out
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content-header">
            <div className="content-fluid">
              <div className="row mt-2">
                <div className="col-md-9 ml-md-2">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Start Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setStartDate(new Date())}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2">
                        <label htmlFor="">End Date</label>
                        <div className="input-group date" id="datetimepicker2">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            onInputClick={() => setEndDate(new Date())}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                          <span className="input-group-addon">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>

                      <div className="col-md-3 mb-2 ">
                        <label htmlFor="">&nbsp;</label>
                        <button
                          className="btn btn-info btn-block "
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Check Out Report</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-md-3 mb-2">
                        <label htmlFor="">Search by Vehicle Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter vehicle number"
                          value={searchInput}
                          onChange={handleSearch}
                        />
                      </div>
                      <div
                        id="example2_wrapper"
                        className="dataTables_wrapper dt-bootstrap4"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-6" />
                          <div className="col-sm-12 col-md-6" />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="table-responsive">
                              <table
                                id="example2"
                                className="table table-bordered table-hover dataTable dtr-inline collapsed"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Rendering engine: activate to sort column ascending"
                                    >
                                      Parking Code
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Vehicle #
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Vehicle Owner
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Date In
                                    </th>
                                    <th
                                      className="sorting sorting_asc"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Browser: activate to sort column descending"
                                      aria-sort="ascending"
                                    >
                                      Date Out
                                    </th>{" "}
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Zone
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Total
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Amount Paid
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Engine version: activate to sort column ascending"
                                    >
                                      Payment Mode
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      aria-controls="example2"
                                      rowSpan={1}
                                      colSpan={1}
                                      aria-label="Platform(s): activate to sort column ascending"
                                    >
                                      Staff
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredCheckouts &&
                                    filteredCheckouts.map((checkout) => {
                                      return (
                                        <Fragment>
                                          <tr className="odd">
                                            <td className="dtr-control">
                                              {" "}
                                              {checkout.id}{" "}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.vehicleNumber.toUpperCase()}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.vehicleOwner.toUpperCase()}
                                            </td>
                                            <td className="dtr-control">
                                              {new Date(
                                                checkout.dateIn
                                              ).toLocaleDateString("en-GB")}
                                            </td>

                                            <td className="dtr-control">
                                              {new Date(
                                                checkout.createdAt
                                              ).toLocaleDateString("en-GB")}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.slotName.toUpperCase()}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.totalAmount}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.amountPaid}
                                            </td>
                                            <td className="sorting_1">
                                              {checkout.paymentMode}
                                            </td>

                                            <td className="dtr-control">
                                              <span class="badge badge-warning">
                                                {checkout.staffName.toUpperCase()}
                                              </span>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="content-wrapper">
          <h3 className="m-2">403 NOT AUTHORIZED</h3>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default CheckOutReport;
