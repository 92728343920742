import {
  UPDATE_ERROR,
  RATES_LOADED,
  ADD_RATE_SUCCESS,
  DELETE_RATE_SUCCESS,
  EDIT_RATE,
  EDIT_SLOTS,
  DELETE_SLOTS_SUCCESS,
  SLOTS_LOADED,
  ADD_SLOTS_SUCCESS,
  PARKINGS_LOADED,
  EDIT_PARKINGS,
  ADD_PARKINGS_SUCCESS,
  DELETE_PARKINGS_SUCCESS,
  ADD_CHECKOUT_SUCCESS,
  SALES_REPORT,
  CHECKIN_REPORT,
  SALES_LOADED,
  PARKED_LOADED,
  PARKED_TODAY_LOADED,
  CHECKEDOUT_TODAY_LOADED,
  CLEAR_ERRORS,
  PARKING_SEARCH,
  ADD_ENTRY,
  ENTRY_LOADED,
  EDIT_ENTRY,
  DELETE_ENTRY,
  DATA_REPORT,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case PARKINGS_LOADED:
      return {
        ...state,

        parkings: action.payload.parkings,
        totalItems: action.payload.totalItems,
      };

    case EDIT_PARKINGS:
      return {
        ...state,
        parkings: state.parkings.map((parkings) =>
          parkings._id === action.payload.id ? action.payload : parkings
        ),
      };

    case ADD_PARKINGS_SUCCESS:
      return {
        ...state,
        parkings: [...state.parkings, action.payload],
        error: "none",
      };

    case DELETE_PARKINGS_SUCCESS:
      return {
        ...state,
        parkings: state.parkings.filter(
          (parkings) => parkings.id !== action.payload
        ),
      };

    case RATES_LOADED:
      return {
        ...state,

        rates: action.payload,
      };

    case ADD_RATE_SUCCESS:
      return {
        ...state,
        rates: [...state.rates, action.payload],
      };

    case EDIT_RATE:
      return {
        ...state,
        rates: state.rates.map((rates) =>
          rates._id === action.payload.id ? action.payload : rates
        ),
      };

    case DELETE_RATE_SUCCESS:
      return {
        ...state,
        rates: state.rates.filter((rates) => rates.id !== action.payload),
      };

    case SLOTS_LOADED:
      return {
        ...state,

        slots: action.payload.slots,

        totalSlots: action.payload.totalSlots,
      };

    case ADD_SLOTS_SUCCESS:
      return {
        ...state,
        slots: [...state.slots, action.payload],
      };

    case ADD_ENTRY:
      return {
        ...state,
        entry: [...state.entry, action.payload],
      };
    case ENTRY_LOADED:
      return {
        ...state,

        entry: action.payload.entries,

        totalEntries: action.payload.totalEntries,
      };

    case EDIT_SLOTS:
      return {
        ...state,
        slots: state.slots.map((slots) =>
          slots._id === action.payload.id ? action.payload : slots
        ),
      };

    case EDIT_ENTRY:
      return {
        ...state,
        entry: state.entry.map((entry) =>
          entry._id === action.payload.id ? action.payload : entry
        ),
      };

    case DELETE_SLOTS_SUCCESS:
      return {
        ...state,
        slots: state.slots.filter((slots) => slots.id !== action.payload),
      };
    case DELETE_ENTRY:
      return {
        ...state,
        entry: state.entry.filter((entry) => entry.id !== action.payload),
      };

    case ADD_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkouts: action.payload,
      };

    case PARKED_LOADED:
      return {
        ...state,

        sales: action.payload.sales,

        totalParked: action.payload.totalParked,

        parkedVehicles: action.payload.parkedVehicles,
      };

    case PARKED_TODAY_LOADED:
      return {
        ...state,

        totalParkedToday: action.payload.totalParkedToday,

        parkedVehiclesToday: action.payload.parkedVehiclesToday,
      };

    case CHECKEDOUT_TODAY_LOADED:
      return {
        ...state,

        totalCheckedOutToday: action.payload.totalCheckedOutToday,

        checkedOutVehiclesToday: action.payload.checkedOutVehiclesToday,

        mpesaSalesToday: action.payload.mpesaSalesToday,
        cashSalesToday: action.payload.cashSalesToday,
        totalSalesToday: action.payload.sumOfAmountPaidToday,
      };

    case SALES_LOADED:
      return {
        ...state,

        sales: action.payload.sales,

        totalSales: action.payload.totalSales,

        sumOfAmountPaid: action.payload.sumOfAmountPaid,
        mpesaSales: action.payload.mpesaSales,
        cashSales: action.payload.cashSales,
      };

    case PARKING_SEARCH:
      return {
        ...state,

        loading: false,
        search: action.payload,
      };

    case SALES_REPORT:
      return {
        ...state,
        checkouts: action.payload,
        startDate: action.startDate,
        endDate: action.endDate,
      };

    case CHECKIN_REPORT:
      return {
        ...state,
        checkins: action.payload,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case DATA_REPORT:
      return {
        ...state,
        data: action.payload,
        startDate: action.startDate,
        endDate: action.endDate,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
